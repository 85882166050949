import './App.scss';
import StoryDemo from './Components/Demo/Story/App'
import FeedDemo from './Components/Demo/Feed/App'
import ReelsDemo from './Components/Demo/Reels/App'
import Reels from './Components/Incheck/Reels/App'   
import Reels2 from './Components/Incheck/Components/Reels/ReelsProd'   
import ReelsDemo2 from './Components/Demo/Components/Reels/ReelsProd'
import Feed from './Components/Incheck/Feed/App'
import FeedInpage from './Components/Incheck2/Feed/App'
import Story from './Components/Incheck/Story/App'
import React,{useEffect, useState, Component} from 'react';
import { BrowserRouter as Router,Route, Routes } from 'react-router-dom';

import Impressum from './Impressum'

function Main() {

 useEffect(()=>{

  document.title = `Instacheck`
 

  const script = document.createElement('script')
  script.src="https://kunde.trend-research.de/multimedia/plugin/2021_adcheck.js"
  script.defer=false
  document.body.appendChild(script)


  return()=>{
    document.body.removeChild(script)
  }


},[])



  return (

    <Router>
   <Routes>
   <Route exact path="/" element={<Impressum/>}/>
    <Route path="/story" element={<StoryDemo/>}/>
    <Route path="/feed" element={<FeedDemo/>}/>
    <Route path="/reels" element={<ReelsDemo2/>}/>
  
   {/* <Route path="/admin" element={<Admin/>}/>*/}
<Route path="/incheckreel" element={<Reels2/>}/>

{/*<Route path="/incheckreel/reelprod" element={<Reels2/>}/> */}
 
 <Route path="/incheckfeed" element={<Feed/>}/>
 <Route path="/incheckinpage" element={<FeedInpage/>}/>
 <Route path="/incheckstory" element={<Story/>}/>

 
   </Routes> 
    </Router>
    
  );
}

export default Main;
